import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/lib/annual-report/post-layout/post-layout.tsx";
import Chart1 from './components/chart-1.tsx';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`PSI teachers are all fully trained university graduates with certification in their areas of instruction. Our teachers are actively engaged with our students, the school community and their own ongoing growth and development. Our staff undertakes professional development initiatives each year and many pursue additional degrees in areas related to their specialisations.`}</p>
    <Chart1 mdxType="Chart1" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      